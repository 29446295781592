<template>
  <div>
    <div class="text-center">
      <div class="font-weight-bold text-color-main">
        Add Components
      </div>
      <v-btn
        v-for="(item, key) in items"
        :key="key"
        small
        depressed
        color="cyan"
        class="ma-1 text-color-white"
        :disabled="item.disabled"
        @click="
          addComponentToBuilder({
            value: item.value,
            componentName: item.componentName
          })
        "
      >
        {{ item.text }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { BUILDER_COMPONENT_EVENT_BUS } from "../services/builderComponentEventBus";

import { RepositoryFactory } from "@/services/repositories/repositoryFactory";

const experienceRepository = RepositoryFactory.get("experience");

export default {
  data: () => ({
    title: "Components",
    items: [
      {
        text: "Add Note",
        value: "note",
        componentName: "NoteComponent",
        disabled: false
      },
      {
        text: "Add Poll",
        value: "poll",
        componentName: "PollComponent",
        disabled: false
      },
      {
        text: "Add Quote",
        value: "quote",
        componentName: "QuoteComponent",
        disabled: false
      },
      {
        text: "Add Image",
        value: "image",
        componentName: "ImageComponent",
        disabled: false
      },
      {
        text: "Add User's Note",
        value: "user_note",
        componentName: "UserNoteComponent",
        disabled: false
      }
    ],
    form: {},
    loading: false
  }),
  methods: {
    addComponentToBuilder(value) {
      BUILDER_COMPONENT_EVENT_BUS.$emit("addComponentToBuilder", value);
    },
    create(form) {
      this.loading = true;

      experienceRepository
        .create(form)
        .then(response => {
          console.log({ response });
        })
        .catch(error => {
          console.log({ error });
        })
        .finally(() => {
          this.loading = false;
          this.MIXINS_SHOW_SNACKBAR("success", "Successfully created.");

          BUILDER_COMPONENT_EVENT_BUS.$emit("clearBuilderData");

          this.MIXINS_GO_TO_PAGE("/experiences");
        });
    },
    update(form, id) {
      this.loading = true;

      experienceRepository
        .update(id, form)
        .then(response => {
          console.log({ response });
        })
        .catch(error => {
          console.log({ error });
        })
        .finally(() => {
          this.loading = false;
          this.MIXINS_SHOW_SNACKBAR("success", "Successfully created.");

          BUILDER_COMPONENT_EVENT_BUS.$emit("clearBuilderData");

          this.MIXINS_GO_TO_PAGE("/experiences");
        });
    },
    submit() {
      let form = {
        contents: this.form
      };

      let id = this.$route.params.id;

      if (id) {
        this.update(id, form);

        return;
      }

      this.create(form);

      return;
    }
  }
};
</script>
